import React, { useEffect, useRef, useState } from "react";
import Isotope from "isotope-layout";
import { Link } from "react-scroll";
import { scrollDuration } from "../config/commonConfig";
import ProjectDetailsModal from "./ProjectDetailsModal";
const Portfolio = () => {
  // init one ref to store the future isotope object
  const isotope = useRef();
  // store the filter keyword in a state
  const [filterKey, setFilterKey] = useState("*");
  const [imagesLoaded, setimagesLoaded] = useState(0);
  const [selectedProjectDetails, setSelectedProjectDetails] = useState();
  const [isOpen, setIsOpen] = useState(false);

  const htmlElement = document.getElementsByTagName("html")[0];
  const isRtl = htmlElement.getAttribute("dir") === "rtl";

  const filters = {
    SERVICE_DESIGN: "Service Design",
    PRODUCT_DESIGN: "Product Desgn",
    YOUTUBE: "Youtube Videos",
    // VIMEO: "Vimeo Videos",
  };

  const types = {
    IMAGE: "image",
    VIDEO: "video",
    DOCUMENT: "document",
  };

  const projectsData = [
    {
      title: "FANNIE MAE",
      type: types.DOCUMENT,
      document: {
        projectInfo:
          "Service Design project for Reston Gateway Employee Workplace Needs",
        client: "Fannie Mae",
        projectDetails: (
          <span>
            Amid the challenges posed by Covid-19, the workplace strategy team
            had identified a critical need to transform the employee workplace
            experience at the Reston Gateway. They sought to thoroughly
            understand and improve Northern Virginia employees' interactions
            with collaboration spaces, technology, tools, and wayfinding
            systems. To address this, the collective expertise of the workplace
            strategy and CXD teams was harnessed to reshape the Reston Gateway
            employee workplace into a safer, more collaborative, and efficient
            environment, despite the constraints of not altering the building's
            structural design due to the implications of Covid-19. The primary
            objectives were twofold: First, to immerse into the daily
            experiences of Northern Virginia employees, gaining insights into
            their use and perception of collaboration spaces, technology, tools,
            and wayfinding systems. The ultimate goal was to create an
            environment that not only supported functionality but also resonated
            with the users' needs, fostering a sense of synergy. Second, to
            analyze the impact of Covid-19 on the dynamics within the Reston
            Gateway building, including shifts in remote and onsite
            collaboration, neighborhood and residency models, and the emergence
            of new safety and security requirements. The team aimed to navigate
            this new normal in a manner that would not only protect employees
            but also boost their productivity. To achieve these objectives, I
            conducted research and facilitated cocreative sessions across the
            enterprise and delivered workplace personas, journey maps, design
            principles and themes, and visual workplace vignettes. These
            deliverables provided a comprehensive understanding of the
            employees' needs and expectations, guiding the transformation of the
            workplace environment to meet the challenges posed by the
            pandemic effectively,
          </span>
        ),

        technologies: "Figma, Mural",
        industry: "UX/UI design",
        date: "3 Months June 2020",
        url: {
          name: "invisionapp.com",
          link: "https://invis.io/JPVIXWUKZ67#/451484530_Fannie_Mae_Case_Study_",
        },

        sliderImages: [],
      },

      thumbImage: "images/projects/project_featured_image.jpg",

      categories: [filters.SERVICE_DESIGN],
    },
    {
      title: "FADV Service Design Project",
      type: types.IMAGE,

      thumbImage: "images/projects/project_featured_image (1).jpg",

      categories: [filters.PRODUCT_DESIGN],
    },
    {
      title: "Johnson & Johnson",
      type: types.DOCUMENT,

      thumbImage: "images/projects/project_featured_image (3).jpg",

      categories: [filters.PRODUCT_DESIGN],
      document: {
        projectInfo: "Service Design Project for Johnson & Johnson ",
        projectDetails: (
          <span>
            {" "}
            I was hired as a UX Researcher & Service Design Consultant to
            support & assist the User Experience & Service Design Manager with
            conducting global enterprise research for Johnson's & Johnson's
            Learning & Development Team & The Corporate Business Technology
            (CBT) group. I was responsible for conducting & synthesizing remote
            and in-person interviews across the US and other countries. My
            responsibilities included the planning and execution of
            collaborative workshops with stakeholders to help gain clarity
            around business objectives and next steps as to prepare for a RFP
            for a new Learning Experience Platform.
            <br />
            <br />
            Key Responsibilities:
            <br />
            <br /> - Planned and executed co-creation collaborative workshops
            with core team & stakeholders to share findings and to propose
            solutions <br />- Recruitment of interview participants
            <br /> - Facilitation of 23 interviews across the US, Europe & Asia
            <br /> - Synthesized & documented interview data and research
            findings
            <br /> - Facilitated & co-facilitated collaborative workshops with
            core-team and stakeholders to gain alignment and to identify &
            clarify business objectives & goals
          </span>
        ),
        client: "Johnson & Johnson",
        technologies: "Figma, Power Point, Adobe Illustrator",
        industry: "UX/UI design",
        url: {
          name: "www.invisionapp.com",
          link: "https://projects.invisionapp.com/share/JPVIXWUKZ67#/screens/445231157_J-J_-1--01",
        },
        date: "September 2020 - December 2020",
        sliderImages: [],
      },
    },
    // {
    //   title: "YouTube Video",
    //   type: types.VIDEO,
    //   video: {
    //     vimeo: false,
    //     id: "PMNnEEEacCg",
    //   },
    //   thumbImage: "images/projects/project-3.jpg",

    //   categories: [filters.YOUTUBE],
    // },
    // {
    //   title: "Vimeo Video",
    //   type: types.VIDEO,
    //   video: {
    //     vimeo: true,
    //     id: "259411563",
    //   },

    //   thumbImage: "images/projects/project-4.jpg",
    //   categories: [filters.VIMEO],
    // },

    {
      title: "Change Health Care",
      type: types.VIDEO,
      video: {
        vimeo: false,
        id: "EoeFKLlXi-k",
      },
      thumbImage: "images/projects/project_featured_image (2).jpg",

      categories: [filters.YOUTUBE],
    },
    {
      title: "Astro Chatbot",
      type: types.DOCUMENT,

      thumbImage: "images/projects/astro_1.jpg",

      categories: [filters.PRODUCT_DESIGN],
      document: {
        projectInfo: "Information Architect ",
        projectDetails: (
          <span>
            As the lead Senior Product Designer, I managed the Astro Chatbot
            project, turning it into a user-friendly tool for PwC's executives.
            Astro uses voice and text commands, powered by AI and machine
            learning, to help busy professionals manage their schedules
            efficiently. My role involved taking the project from an idea to a
            fully functional product, ensuring it met both PwC’s vision and the
            specific needs of its users. The design process began with
            understanding the daily challenges faced by executives, followed by
            brainstorming and conceptualization to create a solution tailored to
            their needs. We developed prototypes, conducted user testing, and
            refined Astro based on feedback, focusing on creating an intuitive
            and easy-to-use interface. Astro integrates seamlessly with PwC’s
            systems, offering timely support and assistance. Astro exemplifies
            how understanding user needs and iterative design can lead to
            creating impactful tools that enhance productivity. My work on Astro
            reflects my commitment to designing products that are not only
            effective but also simple and engaging for the user.
          </span>
        ),

        client: "PWC",
        technologies: "Figma, Power Point,Adobe Illustrator",
        industry: "UX/UI design",
        url: {
          name: "",
          link: "",
        },
        date: "",
        sliderImages: ["images/projects/astro_2.jpg"],
      },
    },
    {
      title: "Westpoint",
      type: types.DOCUMENT,

      thumbImage: "images/projects/infor_arch.jpg",

      categories: [filters.PRODUCT_DESIGN],
      document: {
        projectInfo: "Information Architect ",
        projectDetails: (
          <span>
            I was brought on board to assist in the overhaul of the West Point
            Military Academy's website.
            <br />
            My key duties included:
            <br />
            {/* <br /> - Organizing and executing both open and closed card sorting
            exercises */}
            {/* <br /> */}
            {/* <br /> */}
            {/* <br /> */}
            <ul>
              <li>
                Organizing and executing both open and closed card sorting
                exercises
              </li>
              <li>
                Analyzing the outcomes of these card sorts to devise a new
                website taxonomy and navigation structure informed by the data.
              </li>
              <li>
                Overhauling the site's navigation and information architecture.
              </li>
              <li>
                Performing user research and usability testing to ensure the
                effectiveness of the redesigned layout.
              </li>
            </ul>
          </span>
        ),

        client: "Westpoint",
        technologies: "Optimal Sort,Adobe XD",
        industry: "UX/UI design",
        url: {
          name: "www.westpoint.com",
          link: "https://www.westpoint.edu/",
        },
        date: "",
        sliderImages: [
          "images/projects/westpoint_1.jpg",
          "images/projects/westpoint_2.jpg",
        ],
      },
    },
    // {
    //   title: "Marvel",
    //   type: types.DOCUMENT,

    //   thumbImage: "images/projects/spiderman_1.jpg",

    //   categories: [filters.PRODUCT_DESIGN],
    //   document: {
    //     projectInfo: "Personal Marvel Project ",
    //     projectDetails: (
    //       <span>
    //         I re-imagined an app for marvel’s spider character for my nephew who
    //         loves spider man. This app was designed to not only entertain but
    //         also educate, with engaging quizzes and facts about the Marvel
    //         spider verse, and it served as a delightful and constructive bonding
    //         activity for our family. This project, highlights my skills in app
    //         development, user experience design, and project conceptualization,
    //         which I believe would be valuable in contributing to the development
    //         and success of any project.
    //       </span>
    //     ),

    //     client: "Personal",
    //     technologies: "Figma, Adobe Illustrator",
    //     industry: "UX/UI design",
    //     url: {
    //       name: "Marvel",
    //       link: "https://www.figma.com/proto/HRM22sHV5RNrwMJVnQ8VaY/WelcomeToSpiderverse-Concept?node-id=645-55869&starting-point-node-id=645%3A55869&locale=en",
    //     },
    //     date: "",
    //     sliderImages: [
    //       "images/projects/spiderman_2.jpg",
    //       "images/projects/spiderman_3.jpg",
    //       "images/projects/spiderman_4.jpg",
    //     ],
    //   },
    // },

    // {
    //   title: "Mockups Design 2",
    //   type: types.IMAGE,

    //   thumbImage: "images/projects/project_featured_image (2).jpg",

    //   categories: [filters.MOCKUPS],
    // },
  ];

  // initialize an Isotope object with configs
  useEffect(() => {
    isotope.current = new Isotope(".portfolio-filter", {
      itemSelector: ".filter-item",
      layoutMode: "masonry",
      originLeft: !isRtl,
    });

    // cleanup
    return () => {
      isotope.current.destroy();
    };
  }, []);

  // handling filter key change
  useEffect(() => {
    if (imagesLoaded) {
      filterKey === "*"
        ? isotope.current.arrange({ filter: `*` })
        : isotope.current.arrange({ filter: `.${filterKey}` });
    }
  }, [filterKey, imagesLoaded]);

  const handleFilterKeyChange = (key) => () => setFilterKey(key);

  const getKeyByValue = (value) => {
    return Object.keys(filters).find((key) => filters[key] === value);
  };

  const getFilterClasses = (categories) => {
    if (categories.length > 0) {
      let tempArray = [];
      categories.forEach((category, index) => {
        tempArray.push(getKeyByValue(category));
      });
      return tempArray.join(" ");
    }
  };

  return (
    <>
      <section id="portfolio" className={"section bg-light"}>
        <div className={"container"}>
          {/* Heading */}
          <p className="text-center mb-2 wow fadeInUp">
            <span className="bg-primary text-dark px-2">Portfolio</span>
          </p>
          <h2 className="text-10 fw-600 text-center mb-5 wow fadeInUp">
            Some of my most recent projects
          </h2>
          {/* Heading end*/}
          {/* Filter Menu */}
          <ul
            className={
              "portfolio-menu nav nav-tabs fw-600 justify-content-start justify-content-md-center border-bottom-0 mb-5 wow fadeInUp"
            }
          >
            <li className="nav-item">
              <button
                className={"nav-link " + (filterKey === "*" ? "active" : "")}
                onClick={handleFilterKeyChange("*")}
              >
                All
              </button>
            </li>
            {Object.keys(filters).map((oneKey, i) => (
              <li className="nav-item" key={i}>
                <button
                  className={
                    "nav-link " + (filterKey === oneKey ? "active" : "")
                  }
                  onClick={handleFilterKeyChange(oneKey)}
                >
                  {filters[oneKey]}
                </button>
              </li>
            ))}
          </ul>
          {/* Filter Menu end */}
          <div className="portfolio wow fadeInUp">
            <div className="row portfolio-filter filter-container g-4">
              {projectsData.length > 0 &&
                projectsData.map((project, index) => (
                  <div
                    className={
                      "col-sm-6 col-lg-4 filter-item " +
                      getFilterClasses(project.categories)
                    }
                    key={index}
                  >
                    <div className="portfolio-box">
                      <div className="portfolio-img">
                        <img
                          onLoad={() => {
                            setimagesLoaded(imagesLoaded + 1);
                          }}
                          className="img-fluid d-block portfolio-image"
                          src={project.thumbImage}
                          alt=""
                        />
                        <div
                          className="portfolio-overlay"
                          onClick={() => {
                            setSelectedProjectDetails(projectsData[index]);
                            setIsOpen(true);
                          }}
                        >
                          <button className="popup-ajax stretched-link border-0 p-0 ">
                            {" "}
                          </button>
                          <div className="portfolio-overlay-details">
                            <p className="text-primary text-8">
                              {project.type === types.DOCUMENT && (
                                <i className="fas fa-file-alt"></i>
                              )}
                              {project.type === types.IMAGE && (
                                <i className="fas fa-image"></i>
                              )}
                              {project.type === types.VIDEO && (
                                <i className="fas fa-video"></i>
                              )}
                            </p>
                            <h5 className="text-white text-5">
                              {project?.title}
                            </h5>
                            <span className="text-light">Category</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>

          <div className="container text-center py-5 wow fadeInUp">
            <a
              className="btn btn-primary rounded-0 smooth-scroll wow rubberBand"
              data-wow-delay="1s"
              smooth="easeInOutQuint"
              duration={scrollDuration}
              href="https://valerie.boxapps.org/Account/Login?ReturnUrl=%2F"
              target="_blank"
            >
              See More
            </a>{" "}
          </div>
        </div>
      </section>
      {/* Modal */}
      {isOpen && (
        <ProjectDetailsModal
          projectDetails={selectedProjectDetails}
          setIsOpen={setIsOpen}
        ></ProjectDetailsModal>
      )}
    </>
  );
};

export default Portfolio;
